import React from 'react'
import { firebaseConfig } from './controllers/firebase'
import { ProfileMenu } from './components/profilemenu'
import { AppBarStart } from './components/appBarStart'
import logo from './assets/images/logo.jpeg'

import { KuiProvider, Route, Routes } from '@knowgistics-coding/ui'
import '@knowgistics-coding/ui/dist/index.css'
import { ViewHome } from './view/home'
import { ViewFiles } from './view/files'
import { ViewProjects } from './view/projects'
import { ViewSearch } from './view/search'
import { ViewUpload } from './view/upload'
import { ViewUsers } from './view/users'
import { ViewFileDetail } from './view/file.detail'

const App = () => {
  return (
    <Routes>
      <Route path='/files' element={<ViewFiles />} />
      <Route path='/files/:id' element={<ViewFileDetail />} />
      <Route path='/projects' element={<ViewProjects />} />
      <Route path='/search' element={<ViewSearch />} />
      <Route path='/upload' element={<ViewUpload />} />
      <Route path='/users' element={<ViewUsers />} />
      <Route path='/' element={<ViewHome />} />
    </Routes>
  )
}

export default function Provider() {
  return (
    <KuiProvider
      logo={logo}
      firebaseConfig={firebaseConfig}
      theme={{
        palette: {
          primary: { main: '#2da888', contrastText: '#fff' },
          secondary: { main: '#e58e1b', contrastText: '#fff' },
          duotone: {
            '--fa-primary-color': '#2da888',
          },
        },
        typography: {
          fontFamily: 'maledpan, sans-serif',
          ...Object.assign(
            {},
            ...[1, 2, 3, 4, 5, 6].map((num) => ({
              [`h${num}`]: {
                fontFamily: 'maledpan, kanit'
              }
            }))
          )
        }
      }}
      sitename={'Food Citizens and Community'}
      profileMenu={<ProfileMenu />}
      appBarStart={<AppBarStart />}
    >
      <App />
    </KuiProvider>
  )
}

import React, { useEffect, useState } from 'react'
import { Container, MainContainer, useLocation } from '@knowgistics-coding/ui'
import { SearchBox } from '../../components/searchbox'
import { SearchController } from '../../controllers/main'
import { VSDataGrid } from './data.grid'
import { Box } from '@mui/system'
import {
  Skeleton,
  ToggleButton,
  ToggleButtonGroup,
  Typography
} from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faList, faTh } from '@fortawesome/pro-duotone-svg-icons'
import { VSCardGrid } from './card.grid'

export const ViewSearch = ({ ...props }) => {
  const { state: navState } = useLocation()
  const [state, setState] = useState({
    loading: true,
    docs: [],
    view: 'card'
  })

  const handleChangeView = (e, view) =>
    view && setState((s) => ({ ...s, view }))

  useEffect(() => {
    document.title = `Search | ${process.env.REACT_APP_SITENAME}`
    if (navState) {
      const control = new SearchController()
      console.log(navState)
      control
        .query(navState)
        .then((docs) => setState((s) => ({ ...s, loading: false, docs })))
    }
  }, [navState])

  return (
    <MainContainer dense>
      <SearchBox state={navState} />
      <Container maxWidth='md'>
        <Box py={8}>
          <Box display='flex' alignItems="center" mb={2}>
            <Box flex={1}>
              <Typography>
                {state?.loading ? (
                  <Skeleton width='30%' />
                ) : (
                  `พบไฟล์ทั้งหมด ${state?.docs?.length} รายการ`
                )}
              </Typography>
            </Box>
            <ToggleButtonGroup
              exclusive
              value={state?.view}
              onChange={handleChangeView}
            >
              <ToggleButton value='list'>
                <FontAwesomeIcon icon={faList} />
              </ToggleButton>
              <ToggleButton value='card'>
                <FontAwesomeIcon icon={faTh} />
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
          {state?.view === 'list' && (
            <VSDataGrid loading={state?.loading} rows={state?.docs} />
          )}
          {state?.view === 'card' && (
            <VSCardGrid loading={state?.loading} rows={state?.docs} />
          )}
        </Box>
      </Container>
    </MainContainer>
  )
}

import { useNavigate, withStyles } from '@knowgistics-coding/ui'
import React, { useEffect, useRef, useState } from 'react'
import { Container } from '@knowgistics-coding/ui'
import { Grid, MenuItem, TextField, Typography } from '@mui/material'
import { cats } from '../../cats'
import { EnhanceContainer } from './container'
import { SearchButton } from './search.button'
import { EnhanceSelect } from './select'
import { useProjects } from '../../controllers/use.projects'

const EnhanceTextField = withStyles((theme) => ({
  root: {
    backgroundColor: 'white'
  }
}))(({ classes: cs, ...props }) => (
  <TextField {...props} InputProps={{ className: cs.root }} />
))

export const SearchBox = ({ history, state, ...props }) => {
  const projects = useProjects()
  const inputRef = useRef()
  const [data, setData] = useState({
    query: '',
    project: '',
    type: '',
    target: '',
    node: ''
  })
  const isComplete = Object.values(data).filter((v) => v).length > 0
  const navigate = useNavigate()

  const handleChange =
    (key) =>
    ({ target: { value } }) =>
      setData((d) => ({ ...d, [key]: value }))
  const handleSearch = () => {
    if (!isComplete) {
      return inputRef?.current?.focus()
    } else {
      navigate('/search', { state: data, replace: true })
    }
  }

  useEffect(() => {
    if (state) {
      setData((d) => ({ ...d, ...(state || {}) }))
    }
  }, [state])

  return (
    <EnhanceContainer>
      <Container maxWidth='md'>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              variant='h3'
              color='inherit'
              paragraph
              sx={{ textAlign: 'center' }}
            >
              ฐานข้อมูล
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <EnhanceTextField
              inputRef={inputRef}
              placeholder='ค้นหา'
              value={data?.query}
              onChange={handleChange('query')}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{ textAlign: 'center', fontWeight: 'bold' }}>
              ตัวกรอง
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <EnhanceSelect
              value={
                projects?.map((p) => p.id).includes(data?.project)
                  ? data?.project
                  : ''
              }
              onChange={handleChange('project')}
            >
              <MenuItem value=''>-- เลือกองค์กร --</MenuItem>
              {projects.map((project) => (
                <MenuItem key={project.id} value={project.id}>
                  {project?.project} ({project.title})
                </MenuItem>
              ))}
            </EnhanceSelect>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <EnhanceSelect
              value={data?.type ?? ''}
              onChange={handleChange('type')}
            >
              <MenuItem value=''>-- เลือกประเภท --</MenuItem>
              {cats?.type?.map(({ label, field }) => (
                <MenuItem key={field} value={field}>
                  {label}
                </MenuItem>
              ))}
            </EnhanceSelect>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <EnhanceSelect
              value={data?.target ?? ''}
              onChange={handleChange('target')}
            >
              <MenuItem value=''>-- เลือกกลุ่มเป้าหมาย --</MenuItem>
              {cats?.target?.map((label) => (
                <MenuItem key={label} value={label}>
                  {label}
                </MenuItem>
              ))}
            </EnhanceSelect>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <EnhanceSelect
              value={data?.star ?? ''}
              onChange={handleChange('star')}
            >
              <MenuItem value=''>-- เลือกพื้นที่ดาวเด่น --</MenuItem>
              {cats?.star?.map((label) => (
                <MenuItem key={label} value={label}>
                  {label}
                </MenuItem>
              ))}
            </EnhanceSelect>
          </Grid>
          <Grid item xs={12} sx={{ textAlign: 'center' }}>
            <SearchButton onClick={handleSearch}>ค้นหา</SearchButton>
          </Grid>
        </Grid>
      </Container>
    </EnhanceContainer>
  )
}

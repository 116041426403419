import React, { useEffect, useState } from 'react'
import { createContext, useContext } from 'react'
import { useMain } from '@knowgistics-coding/ui'
import { ProjectController } from '../../controllers/main'

const Ctx = createContext({})
const Pvd = ({ children, ...props }) => {
  const { user } = useMain()
  const [control, setControl] = useState()
  const [state, setState] = useState({
    loading: true,
    docs: []
  })

  const store = {
    ...props,
    states: [state, setState],
    control,
    user
  }

  useEffect(() => {
    const control = new ProjectController()
    setControl(control)
    return control?.watch((docs) =>
      setState((s) => ({ ...s, docs, loading: false }))
    )
  }, [])

  return <Ctx.Provider value={store}>{children}</Ctx.Provider>
}

export const connectViewProject = (Comp) => (props) => {
  return (
    <Pvd {...props}>
      <Comp {...props} />
    </Pvd>
  )
}

export const useProject = () => {
  const store = useContext(Ctx)
  return store
}

import { useEffect, useState } from "react"
import { MainController } from "./main"

export const useProjects = () => {
  const [projects,setProjects] = useState([])

  useEffect(()=>{
    const control = new MainController();
    control.getProjects().then(docs => setProjects(docs))
  }, [])

  return projects;
}
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  increment,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  updateDoc,
  where,
  writeBatch,
  runTransaction,
} from "firebase/firestore";
import axios from "axios";
import app, { auth, db } from "../controllers/firebase";

export class MainController {
  constructor() {
    if (!process.env.REACT_APP_PREFIX) {
      throw new Error("REACT_APP_PREFIX not define in .env file");
    }

    this.prefix = process.env.REACT_APP_PREFIX;

    this.app = app;
    this.auth = auth;
    this.db = db;
  }
  path = (...args) => {
    const ref =
      args.length % 2 === 0
        ? doc(this.db, "clients", this.prefix, ...args)
        : collection(this.db, "clients", this.prefix, ...args);
    return ref;
  };
  toDoc = (doc) => ({ ...doc.data(), id: doc.id });
  currentUser = () => this.auth.currentUser;

  uploadURL = "https://cdn.okkc.in:8082";
  upload = async (files, callback) => {
    if (!files.length) throw new Error("File not found");
    const file = files[0];

    const token = await this?.currentUser()?.getIdToken();
    const data = new FormData();
    data.append("attach", file);
    data.append("token", token);

    const config = {
      onUploadProgress: (progressEvent) => {
        let progress = (progressEvent.loaded / progressEvent.total) * 100;
        progress = Math.floor(progress * 1);
        typeof callback === "function" && callback({ progress });
      },
    };
    const result = await axios.post(`${this.uploadURL}/upload`, data, config);
    if (result?.date?.error) throw new Error(result?.data?.message);
    return result?.data;
  };

  getProjects = async () => {
    const docs = (
      await getDocs(query(this.path("projects"), orderBy("project", "asc")))
    ).docs.map(this.toDoc);
    return docs;
  };
}

export class SearchController extends MainController {
  query = async (state) => {
    const ref = this.path("documents");
    let wheres = [where("visibility", "==", "public")];
    Object.keys(state).forEach((key) => {
      if (state[key]) {
        if (["project", "type"].includes(key)) {
          wheres.push(where(`data.${key}`, "==", state[key]));
        } else if (["target"].includes(key)) {
          wheres.push(where(`data.${key}`, "array-contains", state[key]));
        } else {
          console.log(key, state[key]);
        }
      }
    });
    let docs = (await getDocs(query(ref, ...wheres))).docs.map(this.toDoc);
    if (state?.query) {
      docs = docs.filter((doc) => {
        const data = JSON.stringify(doc).toLowerCase();
        console.log(doc);
        const q = state?.query?.toLowerCase()?.split(" ") ?? [];
        return q.every((txt) => data.includes(txt));
      });
    }
    return docs;
  };
}

export class FileController extends MainController {
  watch = (callback) => {
    return onSnapshot(this.path("documents"), (snap) => {
      const docs = snap.docs.map(this.toDoc);
      callback(docs);
    });
  };
  add = async ({ files, ...data }, callback) => {
    const result = await this.upload(files, (res) => {
      if (typeof callback === "function") {
        callback(res);
      }
    });
    const doc = await addDoc(this.path("documents"), {
      data,
      datecreate: serverTimestamp(),
      datemodified: serverTimestamp(),
      visibility: "public",
      ...result,
    });
    if (data?.project && data?.type) {
      await updateDoc(this.path("projects", data?.project), {
        [data?.type]: increment(1),
      });
    }
    return doc.id;
  };
  removes = async (docs) => {
    if (Array.isArray(docs) && docs?.length) {
      const increments = docs?.reduce((total, doc) => {
        const project = doc?.data?.project ?? "";
        const type = doc?.data?.type ?? "";
        if (!total?.[project]) {
          total[project] = {};
        }
        total[project][type] = (total?.[project]?.[type] ?? 0) - 1;
        return total;
      }, {});
      const batch = writeBatch(this.db);

      (docs || []).forEach((doc) => {
        batch.delete(this.path("documents", doc.id));
      });
      await batch.commit();
      await Promise.all(
        Object.keys(increments).map((key) => {
          return new Promise((res) => {
            runTransaction(this.db, async (transaction) => {
              const sfDoc = await transaction.get(this.path("projects", key));
              if (!sfDoc.exists()) {
                res(false);
              } else {
                const data = Object.assign(
                  {},
                  ...Object.keys(increments[key]).map((k) => ({
                    [k]: increment(increments[key][k]),
                  }))
                );
                transaction.update(this.path("projects", key), data);
                res(true)
              }
            });
          });
        })
      );
    }
  };
}

export class UsersController extends MainController {
  watch = (callback) => {
    return onSnapshot(
      query(this.path("roles"), where("level", ">", 0)),
      (snapshot) => {
        if (typeof callback === "function") {
          callback(snapshot.docs.map(this.toDoc));
        }
      }
    );
  };
  add = (text) =>
    new Promise(async (res) => {
      const level = 1;
      const token = await this.currentUser().getIdToken(true);
      axios
        .post(`${this.uploadURL}/claims/${this.prefix}`, {
          // .post(`http://localhost:8082/claims/${this.prefix}`, {
          token,
          data: { q: text, level },
        })
        .then(async ({ data }) => {
          await this.currentUser().getIdToken(true);
          res(data);
        })
        .catch((error) => res(error?.response?.data));
    });
  remove = async (uid) => {
    if (uid) {
      const level = 0;
      const token = await this.currentUser().getIdToken(true);
      const result = await axios
        .post(`${this.uploadURL}/claims/${this.prefix}`, {
          // .post(`http://localhost:8082/claims/${this.prefix}`, {
          token,
          data: { q: uid, level },
        })
        .catch((err) => console.log(err));
      return result?.data?.success ?? false;
    }
  };
}

export class ProjectController extends MainController {
  watch = (callback) => {
    return onSnapshot(
      query(this.path("projects"), orderBy("title", "asc")),
      (snapshot) => {
        const docs = snapshot.docs.map(this.toDoc);
        callback(docs);
      }
    );
  };
  add = async ({ title, project }) => {
    await addDoc(this.path("projects"), {
      title,
      project,
    });
  };
  update = async (id, data) => {
    await updateDoc(this.path("projects", id), data);
  };
  remove = async (id) => {
    await deleteDoc(this.path("projects", id));
  };
}

export class FileDetailController extends MainController {
  get = async (id) => {
    const doc = await getDoc(this.path("documents", id));
    return doc.data();
  };
}

import React, { useEffect, useState } from 'react'
import {
  Container,
  ContentHeader,
  DataGrid,
  DialogRemove,
  KuiActionIcon,
  MainContainer,
  useMain
} from '@knowgistics-coding/ui'
import { UsersController } from '../../controllers/main'
import { isAdmin } from '../../controllers/is.admin'
import { USAdd } from './add'
import { Avatar } from '@mui/material'

export const ViewUsers = (props) => {
  const { user } = useMain()
  const [control, setControl] = useState()
  const [state, setState] = useState({
    loading: true,
    rows: [],
    remove: null
  })

  const handleRemove = (remove) => () => setState((s) => ({ ...s, remove }))
  const handleRemoveConfirm = async () => {
    await control.remove(state?.remove?.id)
    handleRemove(null)()
  }

  useEffect(() => {
    document.title = `Users | ${process.env.REACT_APP_SITENAME}`
    if ((user?.claims?.[`${process.env.REACT_APP_PREFIX}lv`] ?? 0) >= 1) {
      const control = new UsersController()
      setControl(control)
      return control.watch((rows) =>
        setState((s) => ({ ...s, loading: false, rows }))
      )
    }
  }, [user])

  return (
    <MainContainer
      loading={!user?.fetched || state?.loading}
      restrict={!isAdmin(user)}
      signInOnly
    >
      <Container>
        <ContentHeader
          label='Users'
          breadcrumbs={[{ label: 'Home', to: '/' }]}
          actions={<USAdd />}
        />
        <DataGrid
          loading={state?.loading}
          rows={state?.rows}
          columns={[
            {
              field: 'action',
              headerName: ' ',
              width: 32,
              filterable: false,
              sortable: false,
              align: 'center',
              renderCell: ({ row }) => (
                <KuiActionIcon tx='remove' onClick={handleRemove(row)} />
              )
            },
            {
              field: 'photoURL',
              headerName: ' ',
              filterable: false,
              sortable: false,
              renderCell: ({ value }) => <Avatar src={value} />,
              width: 64,
              align: 'center'
            },
            { field: 'displayName', headerName: 'Name', width: 240 }
          ]}
          disableSelectionOnClick
        />
        <DialogRemove
          open={Boolean(state?.remove)}
          onClose={handleRemove(null)}
          onConfirm={handleRemoveConfirm}
        />
      </Container>
    </MainContainer>
  )
}

import { CircularProgress, Grid, styled, Typography } from '@mui/material'
import React from 'react'
import { DisplayImage, Link } from '@knowgistics-coding/ui'
import clsx from 'clsx'
import { Box } from '@mui/system'

const FileCard = styled(({ className, file, loading, ...props }) => {
  const linkProp = file?.id
    ? { component: Link, to: `/files/${file.id}`, target: '_blank' }
    : {}
  return (
    <Grid item xs={6} sm={4} md={3}>
      <Box className={className} {...linkProp}>
        <div
          className={clsx('inner', {
            loading: loading
          })}
        >
          {loading ? (
            <CircularProgress size={64} color='inherit' />
          ) : (
            <React.Fragment>
              <DisplayImage data={file} thumbnail />
              <div className='title'>
                <Typography variant='caption'>{file?.data?.title}</Typography>
              </div>
            </React.Fragment>
          )}
        </div>
      </Box>
    </Grid>
  )
})(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme?.palette?.grey[100],
  transition: 'transform 0.25s',
  cursor: 'pointer',
  display: 'block',
  '&:hover': {
    transform: 'translateY(-8px)'
  },
  '&:before': {
    content: "''",
    display: 'block',
    paddingTop: '100%'
  },
  '& .inner': {
    ...(theme?.shape?.fluid ?? {})
  },
  '& .loading': {
    color: theme?.palette?.grey[300],
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  '& .title': {
    position: 'absolute',
    bottom: '0',
    width: '100%',
    backgroundColor: 'rgba(0,0,0,0.5)',
    color: 'white',
    padding: theme?.spacing(0.5, 1),
    boxSizing: 'border-box',
    lineHeight: 1.1
  }
}))

export const VSCardGrid = ({ loading, rows, ...props }) => {
  return (
    <Grid container spacing={2}>
      {loading ? (
        <FileCard loading />
      ) : (
        rows.map((row) => <FileCard file={row} key={row.id} />)
      )}
    </Grid>
  )
}

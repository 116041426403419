import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { initializeApp } from 'firebase/app'

export const firebaseConfig = {
  apiKey: 'AIzaSyDXDkEkLCv4y77jfXlQw4bgwUjLAidf3t4',
  authDomain: 'phra-in.firebaseapp.com',
  projectId: 'phra-in',
  storageBucket: 'phra-in.appspot.com',
  messagingSenderId: '557901104441',
  appId: '1:557901104441:web:c9c0affac3d4e4deb3d4e8'
}

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app)
export const db = getFirestore(app)

export default app
import React, { useEffect, useState } from 'react'
import { DialogPrompt, KuiButton, useMain } from '@knowgistics-coding/ui'
import { UsersController } from '../../controllers/main'

export const USAdd = () => {
  const { user } = useMain()
  const [control, setControl] = useState()

  const handleConfirm = async (text) => {
    const result = await control.add(text)
    if (typeof result?.success === 'boolean') {
      return result?.success
    } else {
      alert(result?.message)
      return false
    }
  }

  useEffect(() => {
    if (user?.data) {
      const control = new UsersController()
      setControl(control)
    } else {
      setControl(null)
    }
  }, [user])

  return (
    <React.Fragment>
      {(user?.claims?.['admin-level'] ?? 0) === 9 && (
        <DialogPrompt
          title='Add User'
          label='User ID or E-mail'
          onConfirm={handleConfirm}
          clearAfterConfirm
        >
          <KuiButton tx='add' />
        </DialogPrompt>
      )}
    </React.Fragment>
  )
}

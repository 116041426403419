import React, { useEffect, useRef, useState } from "react";
import {
  Container,
  ContentHeader,
  KuiButton,
  MainContainer,
} from "@knowgistics-coding/ui";
import { Button, Grid, TextField } from "@mui/material";
import { ULPreSelect } from "./select";
import { cats } from "../../cats";
import { ULFiles } from "./files";
import { isAdmin } from "../../controllers/is.admin";
import { connectUpload, useUpload } from "./ctx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/pro-duotone-svg-icons";

const defaultData = {
  files: [],
  title: "",
  project: "",
  type: "",
  subtype: "",
  target: [],
  org: [],
  star: [],
  settings: [],
  refname: "",
  refyear: "",
  tag: "",
};
export const ViewUpload = connectUpload((props) => {
  const {
    user,
    states: [state, setState],
    control,
    addAlert,
  } = useUpload();
  const inputRef = useRef({});
  const [data, setData] = useState({ ...defaultData });
  const [progress, setProgress] = useState(0);
  // const isComplete = !Object.values(data)
  //   .map((val) => Boolean(Array.isArray(val) ? val?.length > 0 : val))
  //   .includes(false);

  const setRef = (key) => (ref) => (inputRef.current[key] = ref);
  const handleChange =
    (key, opts) =>
    ({ target: { value } }) =>
      setData((s) => ({ ...s, ...(opts || {}), [key]: value }));
  const handleUpload = async ({ target: { files } }) => {
    if (files.length) {
      setData((s) => ({ ...s, files, title: files?.[0]?.name ?? "" }));
    }
  };
  const handleRemove = () => setData((s) => ({ ...s, files: [] }));
  const handleSave = async () => {
    if (!data.files.length) {
      alert("กรุณาเลือกไฟล์ที่ต้องการอัปโหลด");
      return null;
    }
    const checks = Object.keys(data).every((key) => {
      const val = data?.[key];
      if (!Boolean(Array.isArray(val) ? val.length > 0 : val)) {
        if (key === "files") {
          return inputRef.current.title.focus();
        } else {
          return inputRef.current?.[key]?.focus() ?? false;
        }
      } else {
        return true;
      }
    });
    if (checks) {
      setState((s) => ({ ...s, loading: true }));
      await control.add(data, ({ progress }) => setProgress(progress));
      addAlert({ label: "Upload file success" });
      const saveLocal = Object.assign(
        {},
        ...Object.keys(data)
          .filter((key) => !["files", "title"].includes(key))
          .map((key) => ({ [key]: data[key] }))
      );
      localStorage.setItem("lastUpload", JSON.stringify(saveLocal));
      setData((d) => ({ ...defaultData, ...saveLocal }));
      setState((s) => ({ ...s, loading: false }));
      setProgress(0);
      localStorage.setItem("upload", JSON.stringify(defaultData));
      handleClear();
    }
  };
  const handleClear = () => {
    setData(defaultData);
  };

  useEffect(() => {
    if (localStorage.getItem("upload")) {
      setData(JSON.parse(localStorage.getItem("upload")));
    }
  }, []);

  useEffect(() => {
    document.title = `Upload | ${process.env.REACT_APP_SITENAME}`;
    if (state.loading === false) {
      const lastUpload = localStorage.getItem("lastUpload");
      if (lastUpload) {
        const data = JSON.parse(lastUpload);
        setData((d) => ({ ...d, ...data }));
      }
    }
  }, [state.loading]);

  useEffect(() => {
    localStorage.setItem("upload", JSON.stringify(data));
  }, [data]);

  return (
    <MainContainer loading={!user?.fetched} restrict={!isAdmin(user)}>
      <Container>
        <ContentHeader
          label="Upload"
          breadcrumbs={[{ label: "Home", to: "/" }]}
        />
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <ULFiles
              files={data?.files}
              onUpload={handleUpload}
              onRemove={handleRemove}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              inputRef={setRef("title")}
              label="ชื่อไฟล์"
              value={data?.title}
              onChange={handleChange("title")}
              disabled={state?.loading}
            />
          </Grid>
          <Grid item xs={12}>
            <ULPreSelect
              inputRef={setRef("project")}
              label="โครงการ"
              value={data.project}
              onChange={handleChange("project")}
              items={
                state?.projects.map(({ project: label, id: field }) => ({
                  label,
                  field,
                })) || []
              }
              disabled={state?.loading}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ULPreSelect
              inputRef={setRef("type")}
              label={cats?.head[0]?.label}
              value={data.type}
              onChange={handleChange("type", { subtype: "" })}
              items={cats?.type ?? []}
              disabled={state?.loading}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ULPreSelect
              inputRef={setRef("subtype")}
              label={cats?.head[1]?.label}
              value={data.subtype}
              onChange={handleChange("subtype")}
              disabled={state?.loading || !Boolean(data?.type)}
              items={cats?.subtype?.[data?.type] ?? []}
            />
          </Grid>
          <Grid item xs={12}>
            <ULPreSelect
              inputRef={setRef("target")}
              label={cats?.head[2]?.label}
              value={data.target}
              onChange={handleChange("target")}
              items={cats?.target ?? []}
              multiple
              helperText="เลือกได้มากกว่า 1"
              disabled={state?.loading}
            />
          </Grid>
          <Grid item xs={12}>
            <ULPreSelect
              inputRef={setRef("org")}
              label={cats?.head[3]?.label}
              value={data.org}
              onChange={handleChange("org")}
              items={cats?.org ?? []}
              multiple
              helperText="เลือกได้มากกว่า 1"
              disabled={state?.loading}
            />
          </Grid>
          <Grid item xs={12}>
            <ULPreSelect
              inputRef={setRef("settings")}
              label={cats?.head[4]?.label}
              value={data.settings}
              onChange={handleChange("settings")}
              items={cats?.settings ?? []}
              multiple
              helperText="เลือกได้มากกว่า 1"
              disabled={state?.loading}
            />
          </Grid>
          <Grid item xs={12}>
            <ULPreSelect
              inputRef={setRef("star")}
              label={cats?.head[5]?.label}
              value={data.star}
              onChange={handleChange("star")}
              items={cats?.star ?? []}
              multiple
              helperText="เลือกได้มากกว่า 1"
              disabled={state?.loading}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              inputRef={setRef("refname")}
              label={cats?.ref?.[0]}
              value={data?.refname}
              onChange={handleChange("refname")}
              disabled={state?.loading}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              inputRef={setRef("tag")}
              label={"คำค้นหา"}
              value={data?.tag}
              onChange={handleChange("tag")}
              disabled={state?.loading}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              inputRef={setRef("refyear")}
              label={cats?.ref?.[1]}
              value={data?.refyear}
              onChange={handleChange("refyear")}
              disabled={state?.loading}
            />
          </Grid>
          <Grid item xs={12} sx={{ textAlign: "center" }}>
            {state?.loading ? (
              <Button
                variant="contained"
                disabled
                startIcon={<FontAwesomeIcon icon={faSpinner} pulse />}
              >
                Upload {progress}%
              </Button>
            ) : (
              <KuiButton tx={"save"} size="large" onClick={handleSave} />
            )}
            &nbsp;
            <KuiButton tx="clear" onClick={handleClear} />
          </Grid>
        </Grid>
      </Container>
    </MainContainer>
  );
});

import React, { useEffect } from 'react'
import { Container, MainContainer } from '@knowgistics-coding/ui'
import { SearchBox } from '../../components/searchbox'
import { Box } from '@mui/system'
import { Typography } from '@mui/material'
import { TagLink } from '../../components/taglink'

export const ViewHome = (props) => {

  useEffect(()=>{
    document.title = `Home | ${process.env.REACT_APP_SITENAME}`;
  }, [])

  return (
    <MainContainer dense>
      <SearchBox history={props?.history} />
      <Box py={6}>
        <Container maxWidth='md'>
          <Typography
            variant='h4'
            textAlign='center'
            color='primary'
            paragraph
          >
            แนวทางการสืบค้นข้อมูล
          </Typography>
          <Typography variant='body1' sx={{ pb: 3 }}>
            ระบบฐานข้อมูลนี้ออกแบบให้สามารถสืบค้นข้อมูลจากคำสำคัญจำนวนมากที่เกี่ยวข้อง&nbsp;
            <strong>
              ผู้สืบค้นสามารถพิมพ์คำสำคัญหลายๆ คำ ลงในช่องค้นหาในครั้งเดียว
              (ไม่ต้องใส่เครื่องหมาย , คั่นแต่ละคำ)
            </strong>{' '}
            เช่น “สื่อความรู้ เกษตรกร ตลาดสีเขียว”
            คำสำคัญแต่ละคำจะถูกสืบค้นโดยแยกตามการเว้นช่องไฟ กรุณาดูโครงสร้างการ
            tags คำสำคัญของฐานข้อมูลนี้ เพื่อเป็นแนวทางในการสืบค้น
          </Typography>
          <TagLink />
        </Container>
      </Box>
    </MainContainer>
  )
}

import React, { useState } from 'react'
import { DialogPre, KuiButton } from '@knowgistics-coding/ui'
import { TextField } from '@mui/material'
import { Box } from '@mui/system'
import pt from 'prop-types'

const defaultData = { title: '', project: '', website: '', facebook: '' }
export const VPAdd = ({
  button,
  value,
  onChange,
  clearAfterConfirm,
  ...props
}) => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState({ ...defaultData, ...(value || {}) })

  const isComplete = Boolean(data?.title && data?.project)

  const handleChange =
    (field) =>
    ({ target: { value } }) =>
      setData((d) => ({ ...d, [field]: value }))
  const handleConfirm = async () => {
    setLoading(true)
    const result = await onChange(data)
    setLoading(false)
    if (clearAfterConfirm) {
      setData((s) => ({ ...defaultData }))
    }
    return result
  }

  return (
    <DialogPre
      loading={loading}
      title={value ? 'Edit' : 'Add'}
      button={button || <KuiButton tx='add' />}
      onConfirm={handleConfirm}
      confirmButtonProps={{
        children: value ? 'Update' : 'Add',
        disabled: !isComplete
      }}
    >
      <Box sx={{ pt: 3 }}>
        <TextField
          label='ชื่อองค์กร/เครือข่าย'
          value={data?.title}
          onChange={handleChange('title')}
          disabled={loading}
        />
        <TextField
          label='ชื่อโครงการ'
          value={data?.project}
          onChange={handleChange('project')}
          disabled={loading}
        />
        <TextField
          label='เว็บไซต์'
          value={data?.website}
          onChange={handleChange('website')}
          disabled={loading}
        />
        <TextField
          label='Facebook'
          value={data?.facebook}
          onChange={handleChange('facebook')}
          disabled={loading}
        />
      </Box>
    </DialogPre>
  )
}
VPAdd.propTypes = {
  value: pt.shape({
    title: pt.string.isRequired,
    project: pt.string.isRequired
  }),
  onChange: pt.func.isRequired,
  clearAfterConfirm: pt.bool
}

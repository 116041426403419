import React from 'react'
import { styled } from '@knowgistics-coding/ui'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/pro-duotone-svg-icons'
import { Button } from '@mui/material'

export const SearchButton = styled((props) => (
  <Button
    variant='contained'
    startIcon={<FontAwesomeIcon icon={faSearch} />}
    size='large'
    color="secondary"
    {...props}
  />
))(({ theme }) => ({
  color: 'white !important',
  borderRadius: `23px !important`,
  paddingLeft: '23px !important',
  paddingRight: '23px !important',
}))

export const catSeatch = {
  type: ["เอกสารวิชาการ", "สื่อความรู้", "วีดีทัศน์"],
  target: ["วัยเด็ก", "วัยทำงาน", "ผู้สูงอายุ", "กลุ่มเป้าหมายเฉพาะ"],
  node: ["โรงเรียน", "โรงพยาบาล", "ผู้ผลิต-ตลาดสีเขียว", "ผู้บริโภค"],
};

export const cats = {
  head: [
    { field: "type", label: "ประเภทข้อมูล" },
    { field: "subtype", label: "ประเภทข้อมูลย่อย" },
    { field: "target", label: "กลุ่มเป้าหมาย" },
    { field: "org", label: "องค์กร" },
    { field: "settings", label: "พื้นที่ทำงาน Settings" },
    { field: "star", label: "พื้นที่ดาวเด่น" },
    { field: "ref", label: "ข้อมูลบรรณานุกรม" },
  ],
  type: [
    { field: "doc", label: "เอกสารวิชาการ" },
    { field: "media", label: "สื่อความรู้" },
    { field: "video", label: "วีดีทัศน์" },
    { field: "image", label: "รูปภาพ" },
  ],
  subtype: {
    doc: [
      "คู่มือสำหรับผู้ปฏิบัติงาน",
      "งานวิจัยฉบับสมบูรณ์",
      "บทความวิชาการ",
      "วารสารวิชาการ",
      "บทคัดย่อ",
      "รายงานสรุป",
      "แบบสำรวจ",
    ],
    media: [
      "คู่มือสำหรับประชาชนทั่วไป",
      "วารสารทั่วไป",
      "หนังสือ",
      "Infographic",
      "แผ่นพับ",
      "สื่อนิทรรศการ",
    ],
    video: ["สารคดี", "ประชาสัมพันธ์", "Motion Graphic", "รายการ"],
    image: ["รูปถ่าย", "Artwork", "Logo"],
  },
  org: [
    "โรงเรียน สถาบันการศึกษา",
    "เกษตรกร",
    "ชุมชน",
    "โรงพยาบาล",
    "องค์กรปกครองส่วนท้องถิ่น",
    "อื่น ๆ",
  ],
  target: [
    "ทารก, เด็กเล็ก",
    "เด็กประถม",
    "เด็กมัธยม",
    "อุดมศึกษา",
    "วัยทำงาน",
    "ผู้สูงอายุ",
    "กลุ่มเป้าหมายเฉพาะ",
  ],
  settings: [
    "โรงเรียน",
    "สถาบันการศึกษา",
    "เกษตรกร",
    "โรงพยาบาล",
    "ชุมชน",
    "หน่วยงานภาครัฐ",
    "ภาคเอกชน",
    "ภาคสังคม",
    "ไม่ใช่งานเชิง Settings",
  ],
  star: [
    "โหนดโรงเรียน",
    "โหนดโรงพยาบาล",
    "โหนดผู้ผลิต-ตลาดสีเขียว",
    "โหนดผู้บริโภค",
    "อื่นๆ",
  ],
  ref: ["รายชื่อผู้เกี่ยวข้อง", "ปีที่เผยแพร่"],
};

import React from 'react'
import pt from 'prop-types'
import {
  Avatar,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction
} from '@mui/material'
import {
  KuiActionIcon,
  KuiButton,
  KuiListItemText
} from '@knowgistics-coding/ui'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileAlt } from '@fortawesome/pro-duotone-svg-icons'
import { useUpload } from './ctx'

export const ULFiles = ({ files, onUpload, onRemove }) => {
  const {
    states: [state]
  } = useUpload()

  return Array.from(files).length ? (
    <List>
      <Divider />
      {Array.from(files || []).map((file, index) => (
        <ListItem dense divider key={index}>
          <ListItemIcon>
            {/image/.test(file?.type) ? (
              <Avatar src={URL.createObjectURL(file)} />
            ) : (
              <Avatar>
                <FontAwesomeIcon icon={faFileAlt} />
              </Avatar>
            )}
          </ListItemIcon>
          <KuiListItemText
            tx='body'
            primary={file?.name}
            secondary={`Size: ${file?.size} byte`}
          />
          <ListItemSecondaryAction>
            {!state?.loading && (
              <KuiActionIcon tx='remove' onClick={onRemove} />
            )}
          </ListItemSecondaryAction>
        </ListItem>
      ))}
    </List>
  ) : (
    <label>
      <input type='file' hidden onChange={onUpload} />
      <KuiButton loading={state?.loading} tx='browse' />
    </label>
  )
}
ULFiles.propTypes = {
  files: pt.oneOfType([pt.array, pt.object]).isRequired,
  onUpload: pt.func.isRequired,
  onRemove: pt.func.isRequired
}

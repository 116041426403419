import React, { useEffect } from "react";
import {
  Container,
  ContentHeader,
  DataGrid,
  DialogRemove,
  KuiButton,
  MainContainer,
  Link as KLink,
} from "@knowgistics-coding/ui";
import { isAdmin } from "../../controllers/is.admin";
import { connectFiles, useFiles } from "./ctx";
import { Avatar, Link } from "@mui/material";
import { cats } from "../../cats";
import { useProjects } from "../../controllers/use.projects";
import { Box } from "@mui/system";

export const ViewFiles = connectFiles((props) => {
  const {
    user,
    states: [state, setState],
    control,
  } = useFiles();
  const projects = useProjects();

  const getProjectLabel = (id) =>
    projects?.find((p) => p.id === id)?.project ?? "";
  const handleSelected = (selected) => setState((s) => ({ ...s, selected }));
  const handleRemove = (remove) => () => setState((s) => ({ ...s, remove }));
  const handleRemoveConfirm = async () => {
    await control.removes(
      state?.docs?.filter((doc) => state?.remove?.includes(doc.id))
    );
    setState((s) => ({ ...s, selected: [], remove: null }));
  };

  useEffect(() => {
    document.title = `Files | ${process.env.REACT_APP_SITENAME}`;
  }, []);

  return (
    <MainContainer
      loading={state?.loading}
      restrict={!isAdmin(user)}
      signInOnly
    >
      <Container maxWidth="md">
        <ContentHeader
          label="Files"
          breadcrumbs={[{ label: "หน้าแรก", to: "/" }]}
        />
        {Boolean(state?.selected?.length) && (
          <Box display="flex" mb={2}>
            <Box flex={1} />
            <KuiButton
              variant="outlined"
              tx="remove"
              onClick={handleRemove(state?.selected)}
            />
          </Box>
        )}
        <DataGrid
          loading={state?.loading}
          rows={state?.docs}
          columns={[
            {
              field: "thumb",
              headerName: " ",
              renderCell: ({ row }) => (
                <Avatar variant="square" src={row?.content?.thumbnail} />
              ),
              width: 64,
              filterable: false,
              sortable: false,
              align: "center",
            },
            {
              field: "name",
              headerName: "ชื่อ",
              width: 240,
              valueGetter: ({ row }) => row?.data?.title ?? "",
              renderCell: ({ row }) => (
                <Link component={KLink} noWrap to={`/files/${row?.id}`} target="_blank">
                  {row?.data?.title}
                </Link>
              ),
            },
            {
              field: "project",
              headerName: "โครงการ",
              width: 240,
              valueGetter: ({ row }) => getProjectLabel(row?.data?.project),
            },
            {
              field: "type",
              headerName: "ประเภท",
              width: 160,
              valueGetter: ({ row }) =>
                cats?.type?.find((item) => item.field === row?.data?.type)
                  ?.label,
            },
            {
              field: "subtype",
              headerName: "ประเภทย่อย",
              width: 160,
              valueGetter: ({ row }) => row?.data?.subtype,
            },
            {
              field: "org",
              headerName: "องค์กร",
              width: 160,
              valueGetter: ({ row }) => row?.data?.org?.join(", "),
            },
            {
              field: "target",
              headerName: "เป้าหมาย",
              width: 160,
              valueGetter: ({ row }) => row?.data?.target?.join(", "),
            },
            {
              field: "node",
              headerName: "โหนด",
              width: 160,
              valueGetter: ({ row }) => row?.data?.node?.join(", "),
            },
            {
              field: "star",
              headerName: "พื้นที่ดาวเด่น",
              width: 160,
              valueGetter: ({ row }) => row?.data?.star?.join(", "),
            },
          ]}
          checkboxSelection
          disableSelectionOnClick
          selectionModel={state?.selected}
          onSelectionModelChange={handleSelected}
        />
      </Container>
      <DialogRemove
        open={Boolean(state?.remove)}
        label={`คุณต้องการลบ ${state?.selected?.length ?? 0} ไฟล์นี้หรือไม่?`}
        onClose={handleRemove(null)}
        onConfirm={handleRemoveConfirm}
      />
    </MainContainer>
  );
});

import React, { createContext, useContext, useEffect, useState } from 'react'
import { useMain } from '@knowgistics-coding/ui'
import { FileController } from '../../controllers/main'

const Ctx = createContext({})

const Pvd = ({ children, ...props }) => {
  const { user, addAlert } = useMain()
  const [control, setControl] = useState(null)
  const [state, setState] = useState({
    loading: true,
    projects: []
  })

  const store = {
    ...props,
    states: [state, setState],
    addAlert,
    control,
    user
  }

  useEffect(() => {
    const control = new FileController()
    setControl(control)
    control
      .getProjects()
      .then((projects) => setState((s) => ({ ...s, projects, loading: false })))
  }, [])

  return <Ctx.Provider value={store}>{children}</Ctx.Provider>
}

export const connectUpload = (Comp) => (props) =>
  (
    <Pvd {...props}>
      <Comp {...props} />
    </Pvd>
  )

export const useUpload = () => useContext(Ctx)

import { Link } from "@mui/material";
import { Link as RLink, styled } from "@knowgistics-coding/ui";
import React from "react";

const LinkStyled = styled((props) => (
  <Link variant="caption" color="textSecondary" {...props} />
))({ textDecoration: "none", "&:not(:last-child)": { marginRight: 8 } });

const A = styled((props) => (
  <Link variant="caption" color="textSecondary" component={RLink} {...props} />
))(({ theme }) => ({
  textDecoration: "none",
}));

export const AppBarStart = (props) => {
  return (
    <div>
      <LinkStyled href="http://foodcitizenandcommunity.com">หน้าเว็บไซต์หลัก</LinkStyled>
      <A to="/projects">ภาคีโครงการ</A>
    </div>
  );
};

import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select
} from '@mui/material'
import React from 'react'
import pt from 'prop-types'

export const ULPreSelect = ({ helperText, ...props }) => {
  return (
    <FormControl fullWidth>
      <InputLabel shrink>{props.label}</InputLabel>
      <Select
        displayEmpty
        input={<OutlinedInput notched label={props.label} />}
        {...props}
      >
        <MenuItem disabled value='' children={`-- เลือก${props.label} --`} />
        {props?.items?.map((item) => (
          <MenuItem key={item?.field ?? item} value={item?.field ?? item}>
            {item?.label ?? item}
          </MenuItem>
        ))}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  )
}
ULPreSelect.propTypes = {
  label: pt.node.isRequired,
  items: pt.arrayOf(
    pt.oneOfType([
      pt.string,
      pt.shape({
        label: pt.string.isRequired,
        field: pt.string.isRequired
      })
    ])
  ).isRequired,
  helperText: pt.node
}

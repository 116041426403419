import React from "react";
import { withStyles } from '@knowgistics-coding/ui'
import bg from '../../assets/images/bg.jpeg'

export const EnhanceContainer = withStyles((theme) => ({
  parent: {
    position: 'relative',
    '&:before': {
      content: "''",
      position: 'absolute',
      display: 'block',
      backgroundImage: `url('${bg}')`,
      backgroundSize: 'cover',
      filter: 'brightness(75%) saturate(40%)',
      width: '100%',
      height: '100%',
      zIndex: -1
    }
  },
  child: {
    padding: `${theme?.spacing(6)} 0`,
    boxSizing: 'border-box',
    color: 'white'
  }
}))(({ classes: cs, ...props }) => (
  <div className={cs.parent}>
    <div className={cs.child} {...props} />
  </div>
))
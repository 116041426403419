import { Button, Typography } from '@mui/material'
import { TreeItem, TreeView } from '@mui/lab'
import { Box } from '@mui/system'
import React from 'react'
import { cats } from '../../cats'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronDown,
  faChevronRight
} from '@fortawesome/pro-duotone-svg-icons'
import {
  useMain,
  styled,
  useNavigate,
  withStyles
} from '@knowgistics-coding/ui'

const ButtonLink = styled((props) => (
  <Button fullWidth variant='contained' color='secondary' {...props} />
))(({ theme }) => ({
  padding: '4px 4px !important',
  borderRadius: '0 !important',
  border: `solid 2px black !important`,
  boxSizing: 'border-box !important',
  fontSize: theme?.typography?.caption?.fontSize + 'px !important',
  lineHeight: '1.3 !important',
  textTransform: 'none !important',
  '&:not(:last-child)': {
    borderBottom: 'none !important'
  }
}))
const Connecter = styled('div')(({ theme }) => ({
  width: 16,
  borderTop: 'solid 2px black'
}))

export const TagLink = withStyles((theme) => ({
  container: {
    display: 'flex',
    backgroundColor: theme?.palette?.grey[200],
    fontSize: theme?.typography?.caption?.fontSize,
    padding: theme?.spacing(2)
  },
  type: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr'
  },
  item: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: theme?.spacing(2),
    paddingBottom: theme?.spacing(2)
  },
  wrap: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  }
}))(({ classes: cs, ...props }) => {
  const { isMobile } = useMain()
  const navigate = useNavigate()
  const getLists = () => {
    return (
      cats?.type?.map((item) => ({
        label: item?.label,
        key: 'type:' + item?.field,
        items: [
          {
            label: cats?.head?.[0].label,
            key: `type:${item?.field}-${cats?.head?.[1]?.label}`,
            items:
              cats?.subtype?.[item?.field]?.map((subtype) => ({
                label: subtype,
                key: `type:${item?.field},subtype:${subtype}`
              })) ?? []
          },
          ...['target', 'org', 'settings', 'star', 'ref'].map((key, index) => ({
            label: cats?.head[index + 2]?.label,
            key: `type:${item?.field},${key}`,
            items:
              cats?.[key]?.map((label) => ({
                label,
                key: `type:${item?.field},${key}:${label}`
              })) ?? []
          }))
        ]
      })) ?? []
    )
  }
  const handleClick = (key) => () => {
    const state = Object.assign(
      {},
      ...key?.split(',').map((txt) => {
        let [name, value] = txt.split(':')
        return { [name]: value }
      })
    )
    navigate('/search', { state, replace: true })
  }

  return (
    <React.Fragment>
      {isMobile ? (
        <TreeView
          defaultExpandIcon={<FontAwesomeIcon icon={faChevronRight} />}
          defaultCollapseIcon={<FontAwesomeIcon icon={faChevronDown} />}
        >
          {getLists()?.map((type) => (
            <TreeItem label={type?.label} nodeId={type?.key} key={type?.key}>
              {type?.items?.map((item) => (
                <TreeItem key={item.key} nodeId={item.key} label={item.label}>
                  {item?.items?.map((sub) => (
                    <TreeItem
                      key={sub.key}
                      nodeId={sub.key}
                      label={sub.label}
                      onClick={handleClick(sub.key)}
                    />
                  ))}
                </TreeItem>
              ))}
            </TreeItem>
          ))}
        </TreeView>
      ) : (
        <div className={cs.container}>
          <div className={cs.type}>
            <div className={cs.grid}>
              {cats?.head?.map((head) => (
                <Box
                  key={head?.field}
                  sx={{
                    pr: 2,
                    textAlign: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center'
                  }}
                >
                  <Typography variant='body2'>{head?.label}</Typography>
                </Box>
              ))}
            </div>
            {getLists()?.map((type) => (
              <div className={cs.grid} key={type.key}>
                <div className={cs.item}>
                  <div className={cs.wrap}>
                    <ButtonLink onClick={handleClick(type.key)}>
                      {type.label}
                    </ButtonLink>
                  </div>
                  <Connecter />
                </div>
                {type.items.map((item, index, { length }) => (
                  <div className={cs.item} key={item.key}>
                    <div className={cs.wrap}>
                      {item.items.map((sub) => (
                        <ButtonLink
                          onClick={handleClick(sub.key)}
                          key={sub.key}
                        >
                          {sub.label}
                        </ButtonLink>
                      ))}
                    </div>
                    {length - 1 > index && <Connecter />}
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      )}
    </React.Fragment>
  )
})

import { Grid, Grow, Skeleton, Typography } from '@mui/material'
import { styled, withStyles, Link, useNavigate } from '@knowgistics-coding/ui'
import { Box } from '@mui/system'
import React from 'react'
import pt from 'prop-types'
import {
  faBook,
  faCamera,
  faFilm,
  faHome,
  faTv
} from '@fortawesome/pro-duotone-svg-icons'
import { faFacebook } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { blue, green, orange, purple, red, yellow } from '@mui/material/colors'

const types = [
  { label: 'เว็บไซต์', field: 'website', icon: faHome, bg: purple[500] },
  { label: 'Facebook', field: 'facebook', icon: faFacebook, bg: blue[500] },
  { label: 'สื่อความรู้', field: 'media', icon: faTv, bg: yellow[700] },
  { label: 'เอกสารวิชาการ', field: 'doc', icon: faBook, bg: green[500] },
  { label: 'รูป', field: 'photo', icon: faCamera, bg: orange[500] },
  { label: 'วีดิทัศน์', field: 'video', icon: faFilm, bg: red[500] }
]

const LinkBox = styled(({ children, disabled, to, ...props }) => {
  const linkProps = to ? { to, component: Link } : {}
  return (
    <Box {...linkProps} {...props}>
      <div className='icon'>{children}</div>
    </Box>
  )
})(({ theme, bg, disabled }) => ({
  position: 'relative',
  backgroundColor: disabled
    ? theme?.palette?.grey[200]
    : bg || theme?.palette?.grey[200],
  color: 'white',
  display: 'block',
  cursor: 'pointer',
  transition: 'transform 0.25s',
  '&>.icon': {
    ...(theme?.shape?.fluid ?? {}),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    lineHeight: 1.1,
    fontSize: theme?.typography?.caption?.fontSize
  },
  '&:before': {
    content: "''",
    display: 'block',
    paddingTop: '100%'
  },
  '&:hover': {
    transform: 'translateY(-8px)'
  }
}))
LinkBox.propTypes = {
  children: pt.node,
  bg: pt.string,
  disabled: pt.bool
}

export const ProjectBox = withStyles((theme) => ({
  root: {
    position: 'relative',
    border: `solid 1px ${theme?.palette?.grey[300]}`,
    borderRadius: (theme?.shape?.borderRadius ?? 4) * 2,
    '&:before': {
      content: '""',
      display: 'block',
      paddingTop: 'calc(100% * 3 / 4)'
    }
  },
  content: {
    ...theme?.shape?.fluid,
    padding: theme?.spacing(2),
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column'
  },
  title: {
    display: '-webkit-box',
    WebkitLineClamp: 3,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  secondary: {
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
}))(({ classes: cs, index, loading, actions, ...props }) => {
  const navigator = useNavigate()
  const getLink = (link) => (!`${link}`.includes('http') ? `//${link}` : link)
  const handleNav = (s) => () =>
    console.log(s) || navigator('/search', { state: s })

  return (
    <Grow in={true} timeout={{ enter: loading ? 0 : (index + 1) * 250 }}>
      <Box className={cs.root}>
        <div className={cs.content}>
          <Box display='flex' alignItems='flex-start'>
            <Box flex={1}>
              <Typography className={cs.title} variant='h6' title={props?.project}>
                {loading ? <Skeleton width='75%' /> : props?.project}
              </Typography>
              <Typography
                className={cs.secondary}
                variant='caption'
                color='textSecondary'
                title={props?.title}
              >
                {loading ? <Skeleton width='50%' /> : props?.title}
              </Typography>
            </Box>
            <Box>{actions}</Box>
          </Box>
          <Box flexGrow={1} />
          <Grid container>
            <Grid item xs={2}>
              <LinkBox
                bg={types?.[0]?.bg}
                to={getLink(props.website)}
                target='_blank'
                disabled={!Boolean(props.website)}
              >
                <FontAwesomeIcon icon={types?.[0]?.icon} />
              </LinkBox>
            </Grid>
            <Grid item xs={2}>
              <LinkBox
                to={getLink(props.facebook)}
                target='_blank'
                bg={types?.[1]?.bg}
                disabled={!Boolean(props.facebook)}
              >
                <FontAwesomeIcon icon={types?.[1]?.icon} />
              </LinkBox>
            </Grid>
            {types.slice(2).map((type) => (
              <Grid item key={type?.field} xs={2}>
                <LinkBox
                  bg={type?.bg}
                  disabled={!Boolean(props?.[type?.field])}
                  onClick={handleNav({
                    type: type?.field,
                    project: props?.id
                  })}
                >
                  <FontAwesomeIcon icon={type?.icon} />
                  {['media', 'doc', 'photo', 'video'].includes(type?.field) &&
                    (props?.[type?.field] ?? 0)}
                </LinkBox>
              </Grid>
            ))}
          </Grid>
        </div>
      </Box>
    </Grow>
  )
})
ProjectBox.defaultProps = {
  media: 0,
  doc: 0,
  photo: 0,
  vdo: 0
}
ProjectBox.propTypes = {
  title: pt.node.isRequired,
  project: pt.node.isRequired,
  index: pt.number.isRequired,
  media: pt.number,
  doc: pt.number,
  photo: pt.number,
  vdo: pt.number,
  actions: pt.node
}

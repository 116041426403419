import React, { useEffect, useState } from 'react'
import {
  Container,
  ContentHeader,
  DisplayImage,
  MainContainer,
  useParams
} from '@knowgistics-coding/ui'
import { FileDetailController } from '../../controllers/main'
import { Button, Skeleton } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/pro-duotone-svg-icons'
import { Box } from '@mui/system'

export const ViewFileDetail = (props) => {
  const { id } = useParams()
  const [, setControl] = useState()
  const [state, setState] = useState({
    loading: true,
    doc: null
  })

  useEffect(() => {
    if (id) {
      const control = new FileDetailController()
      setControl(control)
      control
        .get(id)
        .then((doc) => {
          document.title = `${doc?.data?.title ?? 'No Title'} | ${process.env.REACT_APP_SITENAME}`
          setState((s) => ({ ...s, loading: false, doc }))
        })
    }
  }, [id])

  return (
    <MainContainer loading={state?.loading}>
      <Container>
        <ContentHeader
          label={
            state?.loading ? (
              <Skeleton width={'30%'} />
            ) : (
              state?.doc?.data?.title ?? 'No Title'
            )
          }
          breadcrumbs={[{ label: 'หน้าแรก', to: '/' }]}
        />
        {state?.doc?.content?.thumbnail && (
          <DisplayImage variant='normal' data={state?.doc} />
        )}
        <Box display='flex' justifyContent='center'>
          <Button
            variant='contained'
            startIcon={<FontAwesomeIcon icon={faDownload} />}
            component={"a"}
            href={state?.doc?.content?.original}
            target="_blank"
          >
            Download
          </Button>
        </Box>
      </Container>
    </MainContainer>
  )
}

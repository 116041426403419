import React, { useEffect, useState } from 'react'
import { createContext, useContext } from 'react'
import { useMain } from '@knowgistics-coding/ui'
import { FileController } from '../../controllers/main'

const Ctx = createContext({})
const Pvd = ({ children, ...props }) => {
  const { user } = useMain()
  const [control, setControl] = useState()
  const [state, setState] = useState({
    loading: true,
    docs: [],
    selected: [],
    remove: null,
  })

  const store = {
    states: [state, setState],
    control,
    user
  }

  useEffect(() => {
    if (user?.fetched) {
      const control = new FileController()
      setControl(control)
      return control?.watch((docs) =>
        setState((s) => ({ ...s, docs, loading: false }))
      )
    }
  }, [user])

  return <Ctx.Provider value={store}>{children}</Ctx.Provider>
}

export const connectFiles = (Comp) => (props) =>
  (
    <Pvd {...props}>
      <Comp {...props} />
    </Pvd>
  )

export const useFiles = () => useContext(Ctx)

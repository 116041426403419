import React from 'react'
import { DataGrid } from '@knowgistics-coding/ui'
import { useProjects } from '../../controllers/use.projects'
import { cats } from '../../cats'
import pt from 'prop-types'
import { Avatar } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileAlt } from '@fortawesome/pro-duotone-svg-icons'

export const VSDataGrid = ({ loading, rows, ...props }) => {
  const projects = useProjects()

  const getProjectName = (id) =>
    projects?.find((p) => p.id === id)?.project ?? ''

  return (
    <DataGrid
      loading={Boolean(loading)}
      rows={rows}
      columns={[
        {
          field: 'thumb',
          headerName: ' ',
          filterable: false,
          sortable: false,
          renderCell: ({ row }) =>
            row?.type === 'image' ? (
              <Avatar variant='square' src={row?.content?.thumbnail} />
            ) : (
              <Avatar>
                <FontAwesomeIcon icon={faFileAlt} />
              </Avatar>
            ),
          width: 64,
          align: 'center',
        },
        {
          field: 'name',
          headerName: 'Name',
          valueGetter: ({ row }) => row?.data?.title,
          width: 240
        },
        {
          field: 'project',
          headerName: 'โครงการ',
          valueGetter: ({ row }) => getProjectName(row?.data?.project),
          width: 240
        },
        {
          field: 'type',
          headerName: cats.head[0].label,
          valueGetter: ({ row }) =>
            cats?.type?.find((t) => t.field === row?.data?.type)?.label ??
            row?.data?.type,
          width: 200
        },
        {
          field: 'subtype',
          headerName: cats.head[1].label,
          valueGetter: ({ row }) => row?.data?.subtype,
          width: 240
        }
      ]}
      disableSelectionOnClick
    />
  )
}
VSDataGrid.defaultProps = {
  loading: true,
  rows: []
}
VSDataGrid.propTypes = {
  loading: pt.bool.isRequired,
  rows: pt.array.isRequired
}

import {
  faCabinetFiling,
  faUpload,
  faUsers
} from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ListItem, ListItemIcon, ListItemText } from '@mui/material'
import React from 'react'
import { Link, useMain } from '@knowgistics-coding/ui'
import { isAdmin } from '../../controllers/is.admin'

export const ProfileMenu = (props) => {
  const { user } = useMain()

  return isAdmin(user) ? (
    <React.Fragment>
      <ListItem dense button component={Link} to={`/files`}>
        <ListItemIcon>
          <FontAwesomeIcon icon={faCabinetFiling} />
        </ListItemIcon>
        <ListItemText primary='Files' />
      </ListItem>
      <ListItem dense button component={Link} to={`/users`}>
        <ListItemIcon>
          <FontAwesomeIcon icon={faUsers} />
        </ListItemIcon>
        <ListItemText primary='Users' />
      </ListItem>
      <ListItem dense button component={Link} to={`/upload`}>
        <ListItemIcon>
          <FontAwesomeIcon icon={faUpload} />
        </ListItemIcon>
        <ListItemText primary='Upload' />
      </ListItem>
    </React.Fragment>
  ) : null
}

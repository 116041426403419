import React, { useEffect, useState } from 'react'
import {
  MainContainer,
  Container,
  ContentHeader,
  KuiActionIcon,
  DialogRemove
} from '@knowgistics-coding/ui'
import { ProjectBox } from '../../components/projectbox'
import { Grid } from '@mui/material'
import { VPAdd } from './add'
import { connectViewProject, useProject } from './ctx'
import { isAdmin } from '../../controllers/is.admin'

export const ViewProjects = connectViewProject((props) => {
  const {
    states: [state],
    control,
    user
  } = useProject()
  const [remove, setRemove] = useState(null)

  const handleAdd = async (data) => {
    await control?.add(data)
    return true
  }
  const handleEdit = (id) => async (data) => {
    await control?.update(id, data)
    return true
  }
  const handleRemove = (remove) => () => setRemove(remove)
  const handleRemoveConfirm = async () => {
    await control?.remove(remove?.id)
    setRemove(null)
  }

  useEffect(()=>{
    document.title = `Project | ${process.env.REACT_APP_SITENAME}`
  }, [])

  return (
    <MainContainer>
      <Container maxWidth='md'>
        <ContentHeader
          label='โครงการ'
          breadcrumbs={[{ label: 'หน้าแรก', to: '/' }]}
          actions={
            isAdmin(user) && <VPAdd clearAfterConfirm onChange={handleAdd} />
          }
        />
        <Grid container spacing={2}>
          {state?.loading ? (
            <Grid item xs={12} sm={6} md={4}>
              <ProjectBox loading title='' project='' index={0} />
            </Grid>
          ) : (
            state?.docs?.map((doc, index) => (
              <Grid item xs={12} sm={6} md={4} key={doc?.id}>
                <ProjectBox
                  index={index}
                  {...doc}
                  actions={
                    isAdmin(user) && (
                      <React.Fragment>
                        <VPAdd
                          button={<KuiActionIcon tx='edit' />}
                          value={doc}
                          onChange={handleEdit(doc?.id)}
                        />
                        <KuiActionIcon
                          tx='remove'
                          onClick={handleRemove(doc)}
                        />
                      </React.Fragment>
                    )
                  }
                />
              </Grid>
            ))
          )}
        </Grid>
      </Container>
      <DialogRemove
        label={`Do you want to remove "${remove?.title}"?`}
        open={Boolean(remove)}
        onClose={handleRemove(null)}
        onConfirm={handleRemoveConfirm}
      />
    </MainContainer>
  )
})
